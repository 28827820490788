<template>
  <!--表格型弹出框选择器-->
  <a-input :value="name" :size="size" :placeholder="'请选择' + title" @click="open">
    <template #suffix>
      <i class="fa fa-sitemap gray"/>
    </template>
  </a-input>
  <a-modal :title="'选择' + title" :visible="show" :confirm-loading="loading" width="760px" class="cc-dialog-list"
           @ok="save" @cancel="show = false">
    <z-search-bar :columns="columns" v-model:filter="filter" @search="load"></z-search-bar>

    <a-table :columns="columns" :data-source="data" row-key="id" :loading="loading" size="small"
             :row-selection="multiple ? { selectedRowKeys: selectedRowKeys, onChange: onTableRowChanged } : null"
             :pagination="pagination"
             :custom-row="onTableRowEvent"
             @change="tableChanged">
    </a-table>

  </a-modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'cc-dialog-list',
    emits: ['selected', 'update:code', 'update:name', 'update:pagination', /* rules自动生成事件 */ 'blur', 'change'],
    props: {
      id: String,
      multiple: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: 'small'
      },
      title: {
        type: String
      },
      code: {
        type: String
      },
      name: {
        type: String
      },
      url: {
        type: String,
        default: null
      },
      args: {
        type: Object,
        default: {}
      },
      columns: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        show: false,
        loading: false,
        filter: {},
        data: [],
        selectedRowKeys: [] as any[],
        selections: [] as any[],
        pagination: {
          total: 0,
          current: 1,
          pageSize: 10
        }
      }
    },
    methods: {
      open() {
        this.load()
        this.show = true
      },
      load() {
        this.loading = true
        let params = { page: this.pagination.current, limit: this.pagination.pageSize, ...this.args, ...this.filter }
        this.get(this.url, params).then((d: any) => {
          this.data = d.data
          this.pagination.total = d.count
          // 设置默认值
          if (this.code) {
            if (this.multiple) {
              // 多选模式
              this.selectedRowKeys = this.code.split(',')
            } else {
              // 单选模式
              this.selectedRowKeys = [this.code]
            }
          }
          this.loading = false
        }).catch((err: any) => {
          this.loading = false
        })
        this.show = true
      },
      tableChanged(pagination: any, filters: any, sorter: any) {
        this.pagination = pagination
        this.load()
      },
      onTableRowChanged(selectedRowKeys: any[], selections: any[]) {
        this.selectedRowKeys = selectedRowKeys
        this.selections = selections
      },
      /**
       * 单选模式
       * @param data
       */
      onTableRowEvent(data: any) {
        return {
          onClick: (event: any) => {
            if (!this.multiple) {
              this.$emit('selected', data)
              this.show = false
            }
          }
        }
      },
      /**
       * 多选模式
       */
      save() {
        this.$emit('selected', this.selections)
        this.show = false
      }
    }
  })
</script>

<style lang="less">
</style>
