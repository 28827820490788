import {App, Plugin} from 'vue'
import {
  Button, Col, ConfigProvider, DatePicker, Divider, Form, FormItem, Input, InputNumber, Layout, LayoutSider, LayoutContent, Modal,
  PageHeader, Pagination, Row, Switch, Table, Textarea, Tree, Upload, RadioGroup, Radio, RadioButton, Card, Tag, Statistic, RangePicker,
  Select, TreeSelect, Descriptions, DescriptionsItem, List, ListItem, ListItemMeta, Drawer, Tabs, TabPane
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
// 自定义通用 组件
import CcDialogTable from './components/cc-dialog-table.vue'
import CcDialogList from './components/cc-dialog-list.vue'
// 自定义业务组件
import ZSearchBar from './components/z-search-bar.vue'
import ZInputDept from './components/z-input-dept.vue'
import ZInputUser from './components/z-input-user.vue'
import ZInputAsset from './components/z-input-asset.vue'


const components = [
  Button, Col, ConfigProvider, DatePicker, Divider, Form, FormItem, Input, InputNumber, Layout, LayoutSider, LayoutContent, Modal,
  PageHeader, Pagination, Row, Switch, Table, Textarea, Tree, Upload, RadioGroup, Radio, RadioButton, Card, Tag, Statistic, RangePicker,
  Select, TreeSelect, Descriptions, DescriptionsItem, List, ListItem, ListItemMeta, Drawer, Tabs, TabPane,
  ZSearchBar, ZInputDept, ZInputUser, ZInputAsset,
  CcDialogTable, CcDialogList
]
const install = (app: App) => {
  components.map((component) => {
    app.component(component.name, component)
  })
}

export default {
  install
} as Plugin
