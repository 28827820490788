import { createApp } from 'vue'
import router from './router'
import {createPinia} from "pinia"
import config from './config/config.json'
import MainPage from './main-page.vue'
import ZcWebComponent, {ConfigManager} from 'zc-web-component'
import tools from './config/tools'
import antdv from './antdv'
import print from 'vue3-print-nb'
// 设置日期中文显示
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')
// 样式载入
import './assets/css/common.less'

// import 'ant-design-vue/lib/notification/style/index.css'
// import 'ant-design-vue/lib/message/style/index.css'

const app = createApp(MainPage)
app.mixin(tools)

const store = createPinia()
app.use(store)
app.use(router)
app.use(print)

// 载入组件
app.use(antdv)

// 添加全局属性：this.$router
app.config.globalProperties.$router = router;
app.config.globalProperties.$store = store;

const env = process.env.NODE_ENV || 'development'
const cc = (config as any)[env]

ConfigManager.setComponentConfig({
  platformCode: cc.platformCode,
  baseUrl: cc.baseUrl,
  baseUploadUrl: '',
  baseDownloadUrl: '',
  baseImageUrl: '',
  loginUrl: cc.loginUrl,
  verifyCode: true
})
ConfigManager.loginCover(() => {
  app.use(ZcWebComponent as any)
  app.mount('#app')
})
// if (env === 'development') {
//   document.cookie = 'token=eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIxMjI5MjQ0MDA2OTc0NjE5NjYxIiwiaXAiOiIxNzIuMTkuMC4xIiwiaXNzIjoi5p2O6JCoLDE1MzY4MjQxMTc1LDEyMzI1Njk3MTYxMTA4LOS6keWNl-WNk-ivmuenkeaKgOaciemZkOWFrOWPuCwxNTM2ODI0MTE3NSwiLCJpYXQiOjE2MzY5ODU0MDN9.qhJlPCK_7bM7BbPtnWSLsI-WbzXmLfuoPP29YAZsoABwUAUE-ZmOPrpA2xcOjkq3g7VVCqBvFE9JbcsKsxtPgw'
// }
