import axios, { AxiosRequestConfig } from 'axios'
import { ConfigManager } from 'zc-web-component'

const ax = axios.create({
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
})

// 允许携带cookie
ax.defaults.withCredentials = true

// 配置拦截器，解决axios会先于ConfigManager初始化，导致baseUrl载入时机不正确的问题
ax.interceptors.request.use((config: AxiosRequestConfig) => {
  if (!config.baseURL) {
    const cfg = ConfigManager.getComponentConfig() || {}
    config.baseURL = cfg.baseUrl
  }
  return config
})

export default ax
